import React, { useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import Main from "./components/Main";
import SignUpUser from "./components/SignUp";
import Login from "./components/Login";
import AddStand from "./components/AddStand";
import StandenList from "./components/StandenList";
import AllCharts from "./components/StandenChart";
import Dashboard from "./components/Dashboard";
import Spinner from "./components/Spinner";
import Logout from "./components/Logout";
import { Router } from "@reach/router";
import { useTransition, animated } from "react-spring";
import { DashBoardContext } from "./components/Provider";
import StandenChart from "./components/StandenChart";
import useLocation from "./hooks/useLocation";
//import WithAnimation from "./components/withAnimation";

//const NotFound = () => <h2>Not Found!</h2>;

const LOGINUSER = gql`
  {
    user {
      id
      email
    }
  }
`;

class Auth extends React.Component {
  render() {
    console.log("Auth User", this.props.user);
    if (this.props.user && this.props.user.id) {
      return this.props.children;
    } else {
      return <Login exact path="/login" />;
    }
  }
}

const Routes = () => {
  const { getUser } = useContext(DashBoardContext);
  const { location } = useLocation();
  const { data, loading } = useQuery(LOGINUSER);
  console.log("Routes", location);
  const transitions = useTransition(location, location => location.key, {
    from: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      transform: "translate3d(100%, 0, 0)"
    },
    enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-100%, 0, 0)" }
  });

  return transitions.map(({ item, props: transition, key }) => (
    <animated.div key={key} style={transition}>
      <Router location={location}>
        <Main exact path="/" />
        <Spinner exact path="/spin" />
        <StandenChart path="/test" />
        <Logout path="/logout" />
        <Dashboard exact path="/dashboard" />
        <StandenList exact path="/standen" />
        <AllCharts exact path="/charts" />
        <SignUpUser exact path="/signup" />
        <Login exact path="/login" />
        <Auth user={getUser()} default>
          <AddStand exact path="/addstand" />
        </Auth>
        {/* <NotFound default /> */}
      </Router>
    </animated.div>
  ));
};

export default Routes;
