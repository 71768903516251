import React, { useContext } from "react";
import { navigate, Link } from "@reach/router";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import { Formik } from "formik";
import * as yup from "yup";
import { DashBoardContext } from "./Provider";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    position: "absolute",
    top: 200,
    left: 200,
    display: "flex",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing.unit,
    width: 200
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  input: {
    display: "none"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: 20,
    width: 300
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  form: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(255, 255, 255)",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    boxSizing: "border-box",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px",
    borderRadius: 2,
    padding: 20,
    minWidth: 300,
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left"
  }
});

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("invalid email address")
    .required(" email required"),
  password: yup
    .string()
    .min(8, "minimum 8 characters")
    .required("password required")
});

const Signin = props => {
  const { classes } = props;
  const { setUser } = useContext(DashBoardContext);
  const signinUser = useMutation(SIGNIN_USER);
  return (
    <Formik
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        //  console.log({ values });
        const { email, password } = values;
        try {
          const result = await signinUser({
            variables: {
              email,
              password
            }
          });
          const {
            user: { id, name },
            token
          } = result.data.signinUser;
          // console.log("result", result);
          await setUser(id, token, email, name);
          await navigate("/");
          //  console.log("result", result);
        } catch (error) {
          console.error(error);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ handleChange, values, handleSubmit, setFieldValue, touched, errors, isSubmitting }) => {
        return (
          <form className={classes.form}>
            <TextField
              name="email"
              className={classes.textField}
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && errors.email && <div>{errors.email}</div>}
            <TextField
              name="password"
              type="password"
              placeholder="Password"
              className={classes.textField}
              value={values.password}
              onChange={handleChange}
            />
            {touched.password && errors.password && <div>{errors.password}</div>}
            <Button
              label="Login"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
            >
              Login
            </Button>
            <Link to="/forgot">Forgot Password?</Link>
          </form>
        );
      }}
    </Formik>
  );
};

const SIGNIN_USER = gql`
  mutation SigninUserMutation($email: String!, $password: String!) {
    signinUser(email: { email: $email, password: $password }) {
      token
      user {
        id
        name
      }
    }
  }
`;

export default withStyles(styles)(Signin);
