import * as React from "react";
import UserList from "./UserList";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  card: {
    minWidth: 345
  },
  media: {
    height: 500
  }
});

const MainPage = ({ classes }) => {
  return (
    <Paper className={classes.root} elevation={1}>
      <p className="App-intro">Currently registered users</p>
      <UserList />
    </Paper>
  );
};
export default withStyles(styles)(MainPage);
