import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER_MUTATION = gql`
  query CURRENT_USER_MUTATION {
    user {
      name
      email
      url
    }
  }
`;

const User = props => {
  return (
    <Query query={CURRENT_USER_MUTATION}>
      {({ data, error, loading }) => {
        if (loading) {
          return "";
        }
        if (!data && !data.user) {
          return "";
        }
        if (data) {
          return props.children(data);
        }
        return "";
      }}
    </Query>
  );
};

export default User;
