import React from "react";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import purple from "@material-ui/core/colors/purple";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

const primary = blue; // #F44336
const accent = purple; // #E040FB

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary,
    accent,
    error: red
  }
});

export default ({ children }) => <MuiThemeProvider theme={muiTheme}>{children} </MuiThemeProvider>;
