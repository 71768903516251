import { differenceInDays } from "date-fns";
import _ from "lodash";

function calcDeltas(energyData) {
  return _.orderBy(energyData, ["created"], ["asc"])
    .slice(1)
    .map(({ created, value }, i) => {
      const newData = {
        created: energyData[i].created,
        difference: differenceInDays(energyData[i].created, created),
        value: (value - energyData[i].value) / differenceInDays(created, energyData[i].created),
        first: value,
        second: energyData[i].value
      };

      return newData;
    });
}

function calcCumulative(startData, energyData) {
  // const datesArray = energyData.map(data => data.created);
  return _.orderBy(energyData, ["created"], ["asc"])
    .slice(1)
    .map(({ created, value }, i) => {
      const newData = {
        created,
        current: value,
        difference: differenceInDays(created, startData.created),
        lastperiod: (value - energyData[i].value) / differenceInDays(created, energyData[i].created),
        value: (value - startData.value) / differenceInDays(created, startData.created),
        cumulative: value - startData.value
      };
      return newData;
    });
}

export { calcDeltas, calcCumulative };
