import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import DeleteStand from "./DeleteStand";
const Stand = styled.div`
  display: grid;
  grid-template-columns: 10fr 3fr;
  grid-template-rows: 1fr 2fr 1fr;
  border: 1px solid lightgrey;
  width: 300px;
  height: 150px;
  background: ${props => (props.background ? props.background : "white")};
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px lightgrey;
  padding: 8px;
  margin: 0px 20px 20px 0px;
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s;
    transform: translateZ(10px);
  }
`;

const StandHeader = styled.div`
  font-size: 16px;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: space-around;
`;

const StandReading = styled.div`
  grid-column: 1/2;
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StandBottom = styled.div`
  grid-column: 1/2;
  grid-row: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StandBottomType = styled.div`
  grid-column: 2;
  grid-row: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const H3 = styled.h3`
  font-weight: 900;
  display: flex;
  flex-direction: row;
`;
const Kader = styled.div`
  border: solid 1px black;
  border-radius: 2px;
  margin: 1px;
`;

const TextReading = ({ value }) => {
  return (
    <H3>
      {value
        .toString()
        .padStart(7, "0")
        .split("")
        .map((item, index) => (
          <Kader key={index}>{item}</Kader>
        ))}
    </H3>
  );
};
const StandenCard = ({ stand }) => {
  const { id, type, created, value } = stand;

  return (
    <Stand key={id}>
      <StandHeader>{format(created, "dddd, DD-MMM-YYYY")}</StandHeader>
      <DeleteStand id={id} />
      <StandReading>
        <TextReading value={value} />
      </StandReading>
      <StandBottom>{type}</StandBottom>
      <StandBottomType>{getType2(type)}</StandBottomType>
    </Stand>
  );
};

const getType2 = val => {
  switch (val) {
    case "Gas":
      return (
        <span role="img" aria-label="gas">
          🔥 m<sup>3</sup>
        </span>
      );
    case "Water":
      return (
        <span role="img" aria-label="water">
          💧 l
        </span>
      );
    case "Electricity":
      return "⚡ KWh";
    default:
      return "⚡";
  }
};

export default StandenCard;
