import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";

const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    user {
      id
      name
      email
      url
    }
  }
`;

function useUser() {
  const { loading, data } = useQuery(CURRENT_USER_QUERY, { suspend: false });
  if (loading) {
    return null;
  }
  console.log("DATA", data);
  if (data) {
    if (data.user) {
      return data.user;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export default useUser;
