import React, { Component, useContext } from "react";
import { navigate } from "@reach/router";
import { DashBoardContext } from "./Provider";

const Logout = () => {
  const { clearUser } = useContext(DashBoardContext);
  clearUser();
  navigate("/");
  return <div />;
};
export default Logout;
