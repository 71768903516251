import React from "react";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { format } from "date-fns";
import { FlexRow } from "../styles";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { navigate } from "@reach/router";
import { GC_USER_ID } from "../constants";
import { QUERY_ALLSTANDEN } from "./StandenList";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    position: "absolute",
    top: "10%",
    left: "10%",
    display: "flex",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing.unit
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  input: {
    display: "none"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class AddStand extends React.Component {
  state = { created: new Date() };

  handleFocus = event => {
    event.target.select();
  };

  filterType = (energyType, values) =>
    values
      .filter(v => v.type === energyType)
      .map(item => item.value)
      .join()
      .toString();
  render() {
    const { created } = this.state;
    const { classes } = this.props;

    const formattedDate = format(created, "YYYY-MM-DD");
    return (
      <React.Fragment>
        <Query query={QUERY_STANDEN}>
          {({ data, loading }) => {
            if (loading) return "loading";
            const { lastStanden } = data;
            console.log(lastStanden);

            return (
              <Mutation mutation={addStandQuery} refetchQueries={[{ query: QUERY_ALLSTANDEN }]}>
                {(createStanden, { data }) => {
                  return (
                    <Formik
                      initialValues={{
                        created: formattedDate
                      }}
                      onSubmit={async (values, { setSubmitting, setErrors }) => {
                        const input = values;
                        const userId = localStorage.getItem(GC_USER_ID);
                        let variables;
                        ["Gas", "Electricity", "Water"].map(async type => {
                          variables = { created, type, value: parseFloat(input[type]), userId: userId };
                          console.log(variables);
                          await createStanden({ variables });
                          navigate("/standen");
                        });

                        console.log({ values });
                      }}
                    >
                      {({ handleChange, values, handleSubmit, setFieldValue, touched, errors, isSubmitting }) => {
                        return (
                          <Paper className={classes.root} elevation={1}>
                            <FlexRow>
                              <TextField
                                id="created"
                                name="created"
                                label="Entry date"
                                type="date"
                                value={values.created}
                                onChange={handleChange}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </FlexRow>
                            {["Gas", "Electricity", "Water"].map(field => {
                              console.log("values", values);
                              return (
                                <TextField
                                  key={field}
                                  name={`${field}`}
                                  id={`${field}`}
                                  label={field}
                                  value={values[field.toLocaleLowerCase]}
                                  placeholder={this.filterType(field, lastStanden)}
                                  onChange={handleChange}
                                  onFocus={this.handleFocus}
                                  type="number"
                                  className={classes.textField}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  margin="normal"
                                />
                              );
                            })}

                            <FlexRow>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmit}
                              >
                                Enter
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={() => navigate("/standen")}
                              >
                                Cancel
                              </Button>
                            </FlexRow>
                          </Paper>
                        );
                      }}
                    </Formik>
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

const addStandQuery = gql`
  mutation addStand($created: DateTime!, $type: String!, $value: Float!, $userId: ID) {
    createStanden(created: $created, type: $type, value: $value, userId: $userId) {
      id
      type
      created
      value
    }
  }
`;

const QUERY_STANDEN = gql`
  {
    allStandens(orderBy: created_DESC) {
      id
      created
      type
      value
    }
    lastStanden: allStandens(orderBy: created_DESC, first: 3) {
      id
      created
      type
      value
    }
  }
`;

export default withStyles(styles)(AddStand);
