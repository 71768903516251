import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./components/Shell";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as HooksProvider } from "react-apollo-hooks";
import DashBoardContext from "./components/Provider";
import dotenv from "dotenv";
import { GC_AUTH_TOKEN, GC_REPO } from "./constants";

import AppRoutes from "./routes";

dotenv.config();
console.log(process.env);

const client = new ApolloClient({
  uri: GC_REPO, // process.env.REACT_APP_GRAPHQLSERVER,
  headers: { authorization: `Bearer ${localStorage.getItem(GC_AUTH_TOKEN)}` || null }
});
const rootElement = document.getElementById("root");
const NewApp = () => (
  <HooksProvider client={client}>
    <ApolloProvider client={client}>
      <DashBoardContext>
        <App>
          <AppRoutes />
        </App>
      </DashBoardContext>
    </ApolloProvider>
  </HooksProvider>
);

render(<NewApp />, rootElement);
//
