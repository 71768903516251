import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Icon } from "../styles";
import { QUERY_ALLSTANDEN } from "./StandenList";
import User from "./User";

const StandIcon = styled(Icon)`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
`;

const DELETE_STAND_MUTATION = gql`
  mutation DELETE_STAND_MUTATION($id: ID!) {
    deleteStanden(id: $id) {
      id
    }
  }
`;

const DeleteStand = ({ id }) => {
  return (
    <User>
      {({ user }) => {
        if (!user) {
          return null;
        }
        return (
          <Mutation mutation={DELETE_STAND_MUTATION} variables={{ id }} refetchQueries={[{ query: QUERY_ALLSTANDEN }]}>
            {deleteStand => (
              <div
                onClick={async () => {
                  console.log("Clicked");
                  const res = await deleteStand();
                  console.log("res", res);
                }}
              >
                <StandIcon name="clear" />
              </div>
            )}
          </Mutation>
        );
      }}
    </User>
  );
};

export default DeleteStand;
