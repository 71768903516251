import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Button from "@material-ui/core/Button";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import Layout from "./AppLayout1";
import { DashBoardContext } from "./Provider";
import useUser from "../hooks/useUser";

const styles = theme => ({
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  root: {
    flexGrow: 1,
    marginBottom: 140
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: theme.typography.button
});

class MenuBar extends React.Component {
  render() {
    const { user, authenticated, classes, onLogout, id, initials } = this.props;
    // const { classes } = this.props;
    const { open } = false; //this.state;
    console.log("user, id", user, id);

    return (
      <AppBar position="static">
        <Toolbar disableGutters={!open}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => navigate(`/`)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex} onClick={() => navigate("/dashboard")}>
            Meterstanden
          </Typography>

          <Button color="inherit" onClick={() => navigate(`/standen`)}>
            Standen
          </Button>
          <Button color="inherit" onClick={() => navigate(`/charts`)}>
            Charts
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              id ? onLogout() : navigate("/login");
            }}
          >
            {authenticated() ? "Logout" : "Login"}
          </Button>
          <IconButton color="inherit">
            {id ? <Avatar className={classes.orangeAvatar}>{initials}</Avatar> : <AccountCircle />}
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

const Shell = props => {
  const { classes, children } = props;
  const {
    user: { id },
    authenticated,
    clearUser,
    getUser
  } = React.useContext(DashBoardContext);
  const loggedinUser = getUser();
  console.log("👋", loggedinUser);
  const currentUser = useUser();
  let initials = "";
  if (loggedinUser.email) {
    initials = loggedinUser.email
      .split("@")[0]
      .split(".")
      .map(name => name[0].toUpperCase())
      .join("");
  }
  return (
    <Layout>
      <div className={classes.root}>
        <MenuBar
          user={currentUser}
          id={id}
          initials={initials}
          classes={classes}
          authenticated={authenticated}
          onLogout={() => {
            clearUser();
          }}
        />
        <main>{children}</main>
      </div>
    </Layout>
  );
};

Shell.propTypes = {
  classes: PropTypes.object.isRequired
};

const me = gql`
  {
    user {
      name
      email
      url
    }
  }
`;
//export default withStyles(styles, { withTheme: true })(PersistentDrawer);
export default withStyles(styles)(Shell);
