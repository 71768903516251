import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";
import { differenceInDays, addYears, closestTo, format } from "date-fns";
import _ from "lodash";
import { calcCumulative } from "../lib/calcDeltas";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold
  },
  table: {
    minWidth: 700
  },
  headerrow: {
    fontSize: 16,
    fontWeight: 800
  }
});

function EnergyTable({ data, classes }) {
  if (!data.length) return <div>Ha</div>;
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerrow}>Datum</TableCell>
          <TableCell className={classes.headerrow}>value</TableCell>
          <TableCell className={classes.headerrow}>Average/day</TableCell>
          <TableCell className={classes.headerrow}>Average/day - last period</TableCell>
          <TableCell className={classes.headerrow}>Cumulative</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, id) => {
          return (
            <TableRow key={id}>
              <TableCell component="th" scope="row">
                {format(row.created, "dddd, DD MMMM YYYY")}
              </TableCell>
              <TableCell>{row.current.toFixed(0)}</TableCell>
              <TableCell>{row.value.toFixed(2)}</TableCell>
              <TableCell>{row.lastperiod.toFixed(2)}</TableCell>
              <TableCell>{row.cumulative.toFixed(2)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const calcDifferenceArray = (standen, type) => {
  const datesArray = standen.map(data => data.created);
  const filterDate = format(closestTo(addYears(Date.now(), -1), datesArray), "YYYY-MM-DD");
  console.log("filterdate", filterDate);
  const energyData = _.orderBy(
    standen
      .filter(stand => stand.type === type && stand.created >= filterDate)
      .map(({ created, value }) => ({ created, value })),
    ["created"],
    ["asc"]
  );
  const [first] = energyData;
  return _.orderBy(calcCumulative(first, energyData.slice(1)), ["created"], ["desc"]);
};

class Dashboard extends React.Component {
  state = { data: [] };
  data = [];

  filterData = type => {
    const filteredByType = _.orderBy(this.data, ["created"]).filter(item => item.type === type);
    const last4Weeks = _.takeRight(filteredByType, 5);
    const firstOf4Weeks = last4Weeks[0];
    const lastOf4Weeks = last4Weeks[4];
    const first = filteredByType[0];
    const last = filteredByType[filteredByType.length - 1];
    const diffDays = differenceInDays(first.created, last.created);
    const perDay = (first.value - last.value) / diffDays;
    const last4WeeksUsage = lastOf4Weeks.value - firstOf4Weeks.value;
    console.log(`the usage is ${perDay * 30} per month, and ${perDay * 365} per year`);
    return { perDay, perMonth: perDay * 30, perYear: perDay * 365, usage: last4WeeksUsage };
  };

  render() {
    const { classes } = this.props;
    return (
      <Query query={queryAllStanden}>
        {({ loading, error, data: { allStandens } }) => {
          if (loading) return <div>Loading</div>;
          if (error) return `Error! ${error.message}`;

          this.data = allStandens;
          const waterData = this.filterData("Water");
          const elecData = this.filterData("Electricity");
          const gasData = this.filterData("Gas");
          // console.log("data", this.data);
          const gasData1 = calcDifferenceArray(allStandens, "Gas");
          const waterData1 = calcDifferenceArray(allStandens, "Water");
          const elecData1 = calcDifferenceArray(allStandens, "Electricity");
          console.log(gasData1);
          return (
            <div className={classes.root}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Gas</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <EnergyTable data={gasData1} classes={classes} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Water</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <EnergyTable data={waterData1} classes={classes} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Electricity</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <EnergyTable data={elecData1} classes={classes} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <div>
                Water per maand: {(waterData.perMonth * 1000).toFixed(0)} l, last 4 weeks{" "}
                {(waterData.usage * 1000).toFixed(0)}
              </div>
              <div>
                Gas per maand: {gasData.perMonth.toFixed(0)} m3, last 4 weeks {gasData.usage} m3
              </div>
              <div>
                Electriciteit per maand: {elecData.perMonth.toFixed(0)} kWh, last 4 weeks {elecData.usage} kWh
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

const queryAllStanden = gql`
  query queryAllStanden {
    allStandens(orderBy: created_DESC) {
      id
      created
      type
      value
    }
  }
`;
export default withStyles(styles)(Dashboard);
