import red from "@material-ui/core/colors/red";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import React, { useRef } from "react";
import { useQuery } from "react-apollo-hooks";
import styled from "styled-components";
import { useTransition, useChain, animated, config } from "react-spring";
import StandenCard from "./Stand";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    right: "120px",
    top: 50,
    backgroundColor: red[500],
    position: "absolute",
    zIndex: 99
  },
  button1: {
    margin: theme.spacing.unit,
    right: "50px",
    top: 50,
    position: "absolute"
  }
});
const UL = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

function StandenWithData(props) {
  const { data, loading } = useQuery(QUERY_ALLSTANDEN);

  if (loading) return <div>Loading</div>;
  const { allStandens: standen } = data;
  return <StandenList standen={standen} {...props} />;
}

function StandenList(props) {
  const { classes, standen } = props;

  const transRef = useRef();

  useChain([transRef], [0.1, 0.3]);
  const transitions = useTransition(standen, item => item.id, {
    ref: transRef,
    unique: true,
    trail: 400 / standen.length,

    from: { opacity: 0, transform: "scale(0)", background: "#222222" },
    enter: { opacity: 1, transform: "scale(1)", background: "#f0f0f0;" },
    leave: { opacity: 0, transform: "scale(0)", background: "#222222" },
    config: config.wobbly
  });
  // console.log("allStandens", calcDifferenceArray(allStandens));
  return (
    <React.Fragment>
      <Fab color="primary" aria-label="Add" className={classes.button} onClick={() => navigate("/addstand")}>
        <AddIcon />
      </Fab>
      <UL key={"UL"}>
        {transitions.map(({ item: stand, props, key }) => (
          <animated.div key={key} style={props}>
            <StandenCard key={`id${stand.id}`} stand={stand} />
          </animated.div>
        ))}
      </UL>
    </React.Fragment>
  );
}

const QUERY_ALLSTANDEN = gql`
  {
    allStandens(orderBy: created_DESC) {
      id
      created
      type
      value
    }
  }
`;

export default withStyles(styles)(StandenWithData);
export { QUERY_ALLSTANDEN };
