import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import format from "date-fns/format";
import differenceInDays from "date-fns/difference_in_days";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { VictoryLabel, VictoryArea, VictoryTheme, VictoryChart, VictoryBar } from "victory";

// const colors = ["#BA68C8", "#c62828", "#90caf9", "#01579b", "#ffa726", "#4db6ac", "#fbc02d", "#4527a0"];

const getEnergyData = (data, type) =>
  _.chain(data)
    .filter(s => s.type === type)
    .sortBy(s => Date.parse(s.created))
    .map(s => _.merge({ week: parseInt(format(s.created, "W")) }, s))
    .map(s => {
      return { week: s.week, created: s.created, date1: Date.parse(s.created), value: s.value };
    })
    .takeRight(6)
    .value();

const diffs = filteredStanden =>
  _.map(_.zip(_.slice(filteredStanden, 0, filteredStanden.length - 1), _.tail(filteredStanden)), item => {
    return {
      week: item[1].week,
      value: parseFloat(((item[1].value - item[0].value) / differenceInDays(item[1].date1, item[0].date1)).toFixed(1)),
      diffays: item[1].date1 - item[0].date1,
      difference: differenceInDays(item[1].date1, item[0].date1)
    };
  });

class StandenChart extends React.Component {
  render() {
    const color = this.props.color || "#c43a31";
    const { classes } = this.props;
    const { deltas = false, type = "Gas" } = this.props;
    let summaries;
    return (
      <Query query={QUERY_ALLSTANDEN}>
        {({ data, loading }) => {
          if (loading) {
            return "loading";
          }
          // const { allStandens } = data;

          summaries = getEnergyData(data.allStandens, type).map(item =>
            Object.assign(item, { date: format(item.created, "DD-MMM") })
          );

          if (deltas) {
            summaries = diffs(summaries);
          }
          //return <div>Hallo</div>;
          const range = summaries.map(item => item.value);
          let ymin;
          let ymax;
          if (range.length > 0) {
            if (!deltas) {
              ymin = Math.floor(Math.min(...range) / 10 - 1) * 10;
              ymax = Math.floor(Math.max(...range) / 10 + 1) * 10.01 + 5;
            } else {
              ymin = Math.floor(Math.min(...range) / 10 - 1);
              ymax = Math.floor(Math.max(...range) / 10 + 1) + 5;
            }
          }
          const titleText = deltas ? `Energie/week- ${type} ` : `Meterstand- ${type} `;
          return (
            <Paper className={classes.root} elevation={1}>
              <VictoryChart width={400} theme={VictoryTheme.material}>
                {deltas ? (
                  <VictoryBar
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 1000 }
                    }}
                    theme={VictoryTheme.material}
                    data={summaries}
                    aligmnent="end"
                    barRatio={0.5}
                    style={{
                      data: {
                        fill: color,
                        fillOpacity: 0.7,
                        stroke: color,
                        strokeWidth: 3
                      }
                    }}
                    x="week"
                    y="value"
                    labels={d => `(${d["value"]})`}
                    labelComponent={<VictoryLabel renderInPortal dy={-10} style={{ color: "#000" }} />}
                  />
                ) : (
                  <VictoryArea
                    type="area"
                    interpolation="natural"
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 1000 }
                    }}
                    theme={VictoryTheme.material}
                    data={summaries}
                    style={{
                      data: {
                        fill: color,
                        fillOpacity: 0.7,
                        stroke: color,
                        strokeWidth: 3
                      },
                      labels: {
                        fontSize: 15,
                        fill: d => (d.x === 3 ? "#000000" : color)
                      }
                    }}
                    x="week"
                    y="value"
                    sortOrder="descending"
                    labels={d => `(${d["value"]})`}
                    domain={{ y: [ymin, ymax] }}
                    labelComponent={<VictoryLabel renderInPortal dy={-10} style={{ color: "#000" }} />}
                  />
                )}
                <VictoryLabel text={titleText} x={100} y={50} />
              </VictoryChart>
            </Paper>
          );
        }}
      </Query>
    );
  }
}

const QUERY_ALLSTANDEN = gql`
  {
    allStandens(orderBy: created_DESC) {
      id
      created
      type
      value
    }
  }
`;

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: "28%",
    minWidth: 300,
    display: "flex",
    margin: 20
  }
});

const StyledStandenChart = withStyles(styles)(StandenChart);

class Charts extends React.Component {
  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <StyledStandenChart type="Gas" />
        <StyledStandenChart type="Electricity" color="#8BC34A" />
        <StyledStandenChart type="Water" color="#81D4FA" />
        <StyledStandenChart type="Gas" deltas={true} color="#BA68C8" />
        <StyledStandenChart type="Electricity" color="#ffa726" deltas={true} />
        <StyledStandenChart type="Water" color="#4527a0" deltas={true} />
      </div>
    );
  }
}

export default Charts;
