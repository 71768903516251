import React from 'react';
import { Form, Input, Button } from '../styles';
import {  graphql } from 'react-apollo';
import gql from 'graphql-tag';

class SignUpUser extends React.Component {
  state = { name: '', email: '', password: '' };
  handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  _onSubmit = async () => {
    const { name, email, password } = this.state;
    await this.props.registerUser({
      variables: {
        name,
        email,
        password
      }
    });
  };

  render() {
    const { name, email, password } = this.state;
    console.log(this.props);
    return (
      <Form width="200px">
        <Input name="name" placeholder="Enter name" value={name} onChange={this.handleChange} />
        <Input name="email" placeholder="Enter email" value={email} onChange={this.handleChange} />
        <Input
          name="password"
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={this.handleChange}
        />
        <Button onClick={this._onSubmit}>Create User</Button>
      </Form>
    );
  }
}

const registerUser = gql`
  mutation registerUser($name: String!, $email: String!, $password: String!) {
    createUser(name: $name, authProvider: { email: { email: $email, password: $password } }) {
      id
    }
  }
`;

export default graphql(registerUser, { name: 'registerUser' })(SignUpUser);
