import React, { useState } from "react";
import { GC_AUTH_EMAIL, GC_AUTH_NAME, GC_AUTH_TOKEN, GC_USER_ID } from "../constants";

const DashBoardContext = React.createContext({
  name: "Joris"
});

const DashBoardContextProvider = ({ children }) => {
  const [user, updateUser] = useState({
    id: localStorage.getItem(GC_USER_ID),
    token: localStorage.getItem(GC_AUTH_TOKEN),
    email: localStorage.getItem(GC_AUTH_EMAIL),
    name: localStorage.getItem(GC_AUTH_NAME)
  });
  const getUser = () => user;
  const setUser = (id, token, email, name) => {
    updateUser({ id, token, email, name });
    localStorage.setItem(GC_USER_ID, id);
    localStorage.setItem(GC_AUTH_TOKEN, token);
    localStorage.setItem(GC_AUTH_EMAIL, email);
    localStorage.setItem(GC_AUTH_NAME, name);
  };
  const clearUser = () => {
    updateUser({ id: "", token: "", email: "", name: "" });
    localStorage.removeItem(GC_USER_ID);
    localStorage.removeItem(GC_AUTH_TOKEN);
    localStorage.removeItem(GC_AUTH_EMAIL);
    localStorage.removeItem(GC_AUTH_NAME);
  };
  const authenticated = () => (user.email ? true : false);
  return (
    <DashBoardContext.Provider value={{ user, setUser, clearUser, getUser, authenticated }}>
      {children}
    </DashBoardContext.Provider>
  );
};
class DashBoardContextProvider1 extends React.Component {
  state = {
    id: localStorage.getItem(GC_USER_ID),
    token: localStorage.getItem(GC_AUTH_TOKEN),
    email: localStorage.getItem(GC_AUTH_EMAIL),
    name: localStorage.getItem(GC_AUTH_NAME),
    setUser: (id, email, token, name) => {
      console.log("setUser");
      this.setState({ id, email, token, name });
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      localStorage.setItem(GC_AUTH_EMAIL, email);
      localStorage.setItem(GC_AUTH_NAME, name);
    },
    getUser: () => {
      return { id: this.state.id, email: this.state.email, token: this.state.token };
    },
    clearUser: () => {
      console.log("clearUser");
      this.state.setUser(null, null, null);
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      localStorage.removeItem(GC_AUTH_EMAIL);
      localStorage.removeItem(GC_AUTH_NAME);
    },
    authenticated: () => (this.state.email ? true : false)
  };
  render() {
    return <DashBoardContext.Provider value={this.state}>{this.props.children}</DashBoardContext.Provider>;
  }
}

export { DashBoardContext };
export default DashBoardContextProvider;
