import React from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { useTrail, animated, useTransition, config } from "react-spring";

function UserListWithData(props) {
  const { loading, data } = useQuery(ALLUSERS);

  if (loading) {
    return <div>Loading</div>;
  }
  const { allUsers: users } = data;
  return <UserList users={users} {...props} />;
}

const UserList = ({ users }) => {
  const transitions = useTransition(users, item => item.id, {
    from: { transform: "translate3d(0,-40px,0)", fontSize: "10px" },
    enter: { transform: "translate3d(0,0px,0)", fontSize: "20px" },
    leave: { transform: "translate3d(0,-40px,0)", fontSize: "10px" },
    config: config.slow
  });
  return transitions.map(({ item: user, props, key }) => (
    <animated.li key={key} style={props}>
      {user.name.toUpperCase()}
    </animated.li>
  ));
};

const ALLUSERS = gql`
  {
    allUsers {
      id
      name
      email
    }
  }
`;

export default UserListWithData;
