import * as React from "react";
import styled, { keyframes } from "styled-components";

const MaterialIcon = props => <i className={`material-icons ${props.className}`}>{props.name}</i>;

export const Icon = styled(MaterialIcon)`
  font-size: 1rem;
  padding: 2px;
  :hover {
    transition: all 0.3s;
    color: #ccc;
    font-weight: 900;
  }
`;

export const Typography = styled.div`
  font-family: Roboto;
`;
export const BasicFlex = styled.div`
  font-family: Roboto;
  display: flex;
  padding: 5px;
`;
export const BG = styled.div`
  background: #e6ecf0;
`;

export const niceblue = "#40a5ed";
export const babyblue = "#ecf6fd";
export const twitterblue = "#1da1f2";
export const Input = styled.input`
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  margin: 8px;
  width: ${props => (props.width ? props.width : "200px")};
  min-height: 28px;
  font-size: 1em;
  line-height: 1.5;
  font-family: "Segoe UI", Roboto;
  font-size: 16px;
  color: ${props => (props.color ? props.color : "rgba(0, 0, 0, 0.65)")};
  background-color: ${props => (props.background ? props.background : "#fff")};
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  ::-webkit-inner-spin-button {
    display: none;
  }
  ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  :before {
    color: transparent;
    background: none;
    display: block;
    font-family: "FontAwesome";
    content: "📅";
    /* This is the calendar icon in FontAwesome */
    width: 15px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #999;
  }
`;

const Option = styled.option`
  font-family: inherit;
  :hover {
    background: black;
  }
`;
export const SelectList = () => (
  <select>
    <Option>Gas</Option>
    <Option>Water</Option>
  </select>
);

export const DateInput = Input.withComponent("input[type=date]");

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: black;
`;
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TextArea = Input.withComponent("textarea");
export const ViewText = Input.withComponent("div");

export const Button = styled.a`
  display: inline-block;
  min-width: 150px;
  width: ${props => (props.width ? props.width : "150px")};
  height: 40px;
  padding: 4px 7px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid ${props => (props.bordercolor ? props.bordercolor : props.color ? props.color : niceblue)};
  text-decoration: none;
  color: ${props => (props.color ? props.color : niceblue)};
  font-family: "Segoe UI", Roboto;
  font-size: 1em;
  background: transparent;
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
  text-align: center;
  line-height: 36px;
  margin: 8px;

  :hover {
    background: ${props => (props.color ? props.color : niceblue)};
    color: white;
  }
`;

export const HR = styled.hr`
  margin: -1px 0px 0px;
  height: 1px;
  border: none;
  color: black;
  background-color: rgb(224, 224, 224);
  display: block;
  unicode-bidi: isolate;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  overflow: hidden;
`;
export const PrimaryButton = styled.a`
  display: inline-block;
  min-width: 150px;
  width: ${props => (props.width ? props.width : "150px")};
  height: 40px;
  padding: 4px 7px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid ${props => (props.color ? props.color : niceblue)};
  text-decoration: none;
  background: ${props => (props.color ? props.color : niceblue)};
  color: white;
  font-family: "Segoe UI", Roboto;
  font-size: 1.1em;
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
  text-align: center;
  line-height: 36px;
  margin: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: lightgrey;
`;

export const Papier = styled.div`
  color: black;
  background-color: ${props => (props.background ? props.background : "white")};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 30px, rgba(0, 0, 0, 0.23) 0px 6px 10px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => (props.width ? props.width : "100%")};

  min-width: 200px;
`;

export const Papier1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: ${props => (props.color ? props.color : "white")};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
  border-radius: 2px;
  width: 30%;
  padding: 20px;
  min-width: 300px;
  margin-top: 40px;
  margin-left: 20px;
  text-align: left;
`;
export const Avatar = styled.img`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  padding: 5px;
  justify-content: center;
  margin-left: 10px;
  overflow: hidden;
`;

export const UserName = styled(BasicFlex)`
  font-size: 16px;
`;

export const AlertBox = styled(Typography)`
  padding: 16px 16px 16px 60px;
  position: relative;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  border: 1px solid #d2eafb;
  background-color: #ecf6fd;
  position: relative;
  padding: 8px 48px 8px 38px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 1.5;
`;

//export const Spinner = () => <WaveLoading />;
export const H1 = styled.h1`
  font-weight: 300;
  font-family: Roboto;
`;

export const Pop = styled(BasicFlex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 20px;
  color: hsla(0, 0%, 93%, 1);
  text-shadow: -1px -1px 0 hsla(0, 0%, 0%, 0.7);
  background: hsla(0, 0%, 0%, 0.85);
  border-radius: 4px;
  border: solid 1px hsla(0, 0%, 20%, 1);
  box-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.7);
`;
export const Pop2 = styled(BasicFlex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  color: hsla(0, 0%, 93%, 1);
  text-shadow: -1px -1px 0 hsla(0, 0%, 0%, 0.7);
  background: hsla(0, 0%, 0%, 0.85);
  border-radius: 4px;
  border: solid 1px hsla(0, 0%, 20%, 1);
  box-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.7);
  :after {
    position: absolute;
    right: 5px;
    top: -5px;
    content: "";
    display: block;
    border-bottom: solid 5px hsla(0, 0%, 0%, 0.85);
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
`;
export const Sym = styled.span`
  display: flex;
  font-size: 16px;
  right: 16px;
  top: 10px;
  color: black;
  outline: none;
  text-decoration: none;
  transition: delay 1s;
  :hover {
    color: blue;
    cursor: pointer;
  }
`;

export const NotificationMsg = styled(Typography)`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 4px;
  line-height: 20px;
  display: inline-block;
`;

export const NotificationText = styled(Typography)`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
`;

//${props => (props.color ? props.color : 'rgb(255, 255, 255)')}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  background-color: rgb(255, 255, 255);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: "Segoe UI", Roboto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
  border-radius: 2px;
  width: ${props => (props.width ? props.width : "50%")};
  padding: 20px;
  min-width: 300px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;

export const Error = styled.div`
  display: flex;
  color: ${props => (props.color ? props.color : "red")};
  border: solid 1px ${props => (props.color ? props.color : "red")};
  border-radius: 5px;
  padding: 20px;
`;
export const Message = styled.div`
  display: flex;
  color: ${props => (props.color ? props.color : "red")};
  border-radius: 5px;
  padding: 20px;
`;

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const Loading = styled.div`
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: ${rotate360};
  animation-timing-function: linear;
  height: 30px;
  width: 30px;
  border: 8px solid #ffffff;
  border-right-color: blue;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 0;
  left: 0%;
  color: blue;
  margin-left: auto;
  margin-right: auto;
`;

export const Presentation = styled.div`
  width: 480px;
  height: 120px;
  padding: 20px;
  margin: auto;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s;
  border-radius: 3px;
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s;
    transform: translateZ(10px);
  }
`;

export const Floating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: ${props => (props.big ? "55px" : props.medium ? "35px" : "55px")};
  height: ${props => (props.big ? "55px" : props.medium ? "35px" : "55px")};
  border-radius: 50%;
  background: ${props => (props.color ? props.color : "#db4437")};
  position: fixed;
  top: ${props => (props.top ? "30px" : "")};
  bottom: ${props => (props.bottom ? "30px" : "")};
  right: ${props => (props.right ? "30px" : "")};
  left: ${props => (props.left ? "30px" : "")};
  cursor: pointer;
  box-shadow: 0px 2px 5px #666;
`;
export const Plus = styled.p`
  color: white;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: ${props => (props.medium ? "20px" : "38px")};
  font-family: "Roboto";
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
`;

const ChipFrame = styled.div`
  border: 10px;
  box-sizing: border-box;
  display: flex;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 4px;
  padding: 0px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  background-color: rgb(224, 224, 224);
  border-radius: 16px;
  white-space: nowrap;
  width: fit-content;
  max-width: 400px;
`;

const ChipSpan = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  user-select: none;
  white-space: nowrap;
`;
const SVG = styled.svg`
  display: inline-block;
  color: rgba(0, 0, 0, 0.26);
  fill: rgba(0, 0, 0, 0.26);
  height: 24px;
  width: 24px;
  user-select: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: pointer;
  margin: 4px 4px 0px -8px;
`;

const ChipImg = styled.img`
  color: rgb(255, 255, 255);
  background-color: rgb(188, 188, 188);
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-right: -4px;
`;

const ChipClose = () => (
  <SVG viewBox="0 0 24 24">
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
  </SVG>
);

export const Chip = ({ label = "This is a chip" }) => (
  <ChipFrame>
    <ChipImg src="http://www.material-ui.com/images/ok-128.jpg" />
    <ChipSpan>{label}</ChipSpan>
    <ChipClose />
  </ChipFrame>
);

export const FloatingPlus = ({ sign = "+", color = "#db4437", size = "big", ...props }) => {
  let dynamicProps = {};
  dynamicProps[size] = size;
  return (
    <Floating top right color={color} {...props} {...dynamicProps}>
      <Plus {...size}>{sign}</Plus>
    </Floating>
  );
};

export const NiceSelect = styled.select`
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  padding-left: 50px;
  margin: 8px;
  min-height: 28px;
  line-height: 1.5;
  font-family: inherit;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  resize: none;
  font-size: 16px;
  width: 257px;
  height: 34px;
`;
